export const getErrorMessge = (err) => {
  const messages = [];
  if (err.errors) {
    const keys = Object.keys(err.errors);
    keys.forEach((key) => {
      for (const message in err.errors[key]) {
        messages.push(err.errors[key][message]);
      }
    });
    if (messages.length > 0) {
      return messages.join('\n');
    }
  }
  return err.message;
};
